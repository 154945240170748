import { createStore } from '@cobuildlab/react-simple-state';
import { SocialProviders } from '../../shared/types/generated';

export const changeAvatarStore = createStore<{ file: File | null }>({
  initialValue: {
    file: null,
  },
});

export const providerConnectStore = createStore<{
  isOpen: boolean;
  provider: SocialProviders | undefined;
}>({
  initialValue: {
    isOpen: false,
    provider: undefined,
  },
});

export const updateProfileStore = createStore<{ isChanged: boolean }>({
  initialValue: {
    isChanged: false,
  },
});
