import React, { useState } from 'react';
import { Country, ICountry, IState, State } from 'country-state-city';
import { Grid, Theme } from '@mui/material';
import { SelectDefault } from '../input/SelectDefault';
import { createStyles, makeStyles } from '@mui/styles';
import { PRIMARY_COLOR } from '../../css/theme';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    input: {
      background: 'white',
      fontSize: '18px',
      height: '38px',
    },
  }),
);

type CountryStateCityContainerProps = {
  onChangeCountry: (name: string, value: string) => void;
  onChangeState: (name: string, value: string) => void;
  countryValue?: string | null;
  stateValue?: string | null;
}

export const CountryStateCityContainer: React.FC<CountryStateCityContainerProps> = (
  {
    onChangeCountry,
    onChangeState,
    countryValue,
    stateValue,
  },
) => {

  const classes = useStyles();

  const countries = Country.getAllCountries();

  const country = countries.find((c) => c.name === countryValue) as ICountry;

  const states = State.getStatesOfCountry(country?.isoCode as string);

  return (
    <Grid container spacing={2}>
      <Grid xs={12} md={6} item>
        <SelectDefault
          placeholder="Seleccionar pais"
          onChangeData={onChangeCountry}
          value={countryValue}
          InputLabelProps={{
            shrink: true,
            style: { fontSize: 19 },
          }}
          variant="outlined"
          InputProps={{
            className: classes.input,
          }}
          name="country"
          options={countries.map((cy) => ({
            label: cy.name,
            value: cy.name as string,
          }))} />
      </Grid>
      <Grid xs={12} md={6} item>
        <SelectDefault
          onChangeData={onChangeState}
          InputLabelProps={{
            shrink: true,
            style: { fontSize: 19 },
          }}
          placeholder="Seleccionar estado"
          variant="outlined"
          InputProps={{
            className: classes.input,
          }} name="city"
          disabled={!(country && states.length)}
          value={stateValue}
          options={states.map((cy) => ({
            label: cy.name,
            value: cy.name as string,
          }))}

        />
      </Grid>
    </Grid>
  );

};