import { createEvent, createStore } from '@cobuildlab/react-simple-state';
import {
  AuthStep,
  AuthStore,
  FacebookUserType,
  GoogleUserType,
} from './auth-types';
import { getEmail, getIsLogin, getStep } from './auth-utils';
import { universalCookies } from '../../shared/cookies';
import { USER_COOKIE } from '../../shared/constans';

export const authGoogleEvent = createEvent<GoogleUserType | undefined>({
  initialValue: undefined,
});
export const authGoogleErrorEvent = createEvent();

export const authFacebookEvent = createEvent<FacebookUserType | undefined>({
  initialValue: undefined,
});
export const authFacebookErrorEvent = createEvent();

export const fetchGoogleUserEvent = createEvent();
export const fetchGoogleUserErrorEvent = createEvent();

export interface InitialStateType {
  token: null | string;
}

const INITIAL_STATE = {
  token: universalCookies.get(USER_COOKIE),
};

export const OnTokenEvent = createEvent<InitialStateType>({
  initialValue: INITIAL_STATE,
});
export const OnTokenErrorEvent = createEvent<Error>();

export const registerUserDataEvent = createEvent<{
  email?: string;
  password?: string;
}>({
  initialValue: {
    email: getEmail(),
    password: '',
  },
});

export const authStore = createStore<AuthStore>({
  initialValue: {
    email: getEmail(),
    isLogin: getIsLogin(),
  },
});

export const authStepStore = createStore<{ step: AuthStep }>({
  initialValue: {
    step: getStep(),
  },
});
