import React, { useEffect } from 'react';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { GOOGLE_CAPTCHA } from '../../shared/constans';
import { AuthResetPasswordForm } from './components/AuthResetPasswordForm';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { AuthForgotPassword } from './components/AuthForgotPassword';
import { useStore } from '@cobuildlab/react-simple-state';
import { authStepStore } from './auth-events';
import { getError } from './auth-utils';
import { AuthStep } from './auth-types';
import { MainLoader } from '../../shared/components/MainLoader';
import { useAuthRedirect } from './auth-hook';
import { AuthForgotPasswordNotFound } from './components/AuthForgotPasswordNotFound';
import { openDialogAlert } from '../alert/alert-actions';
import { AuthSwitch } from './components/AuthSwitch';
import { AuthSocialProviders } from './components/AuthSocialProviders';
import { AuthRegisterForm } from './components/AuthRegisterForm';
import { AuthLoginForm } from './components/AuthLoginForm';

export const Auth: React.FC = () => {
  const { step } = useStore(authStepStore);

  const { isAuthenticated } = useAuthRedirect('/');

  useEffect(() => {
    const error = getError();
    if (error) {
      openDialogAlert(error);
    }
  }, []);

  let content: JSX.Element;

  switch (step) {
    case AuthStep.authRegister:
      content = <AuthRegisterForm />;
      break;
    case AuthStep.authResetPassword:
      content = <AuthResetPasswordForm />;
      break;
    case AuthStep.authForgotPassword:
      content = <AuthForgotPassword />;
      break;
    case AuthStep.authForgotPasswordNotFound:
      content = <AuthForgotPasswordNotFound />;
      break;
    default:
      content = <AuthLoginForm />;

      break;
  }

  if (isAuthenticated) return <MainLoader />;
  return (
    <GoogleReCaptchaProvider reCaptchaKey={GOOGLE_CAPTCHA as string}>
      <Box px={2} display="flex">
        <Grid container  justifyContent="center" spacing={2}>
          <Grid item xs={12}>
            <Grid container justifyContent="center" alignItems={'center'}>
              <Grid xs={12} display={'flex'} justifyContent={'center'} alignItems={'center'} item>
                <Box
                  border={'1px solid #D6D6D6'}
                  borderRadius={'11px'}
                  padding={'20px'}
                  style={{ background: 'white' }}
                  mt={2}
                  mb={2}
                  width={400}
                  minWidth={320}>
                    {
                      [AuthStep.authRegister, AuthStep.authLogin].includes(
                        step,
                      ) && (
                      <Grid marginTop={0} marginBottom={2} item xs={12}>
                      <Typography
                        textAlign={'center'}
                        fontSize={'20px'}
                        fontWeight={'bold'}
                        lineHeight={'26px'}>
                        {step===AuthStep.authLogin?'INICIAR SESIÓN':'REGÍSTRATE'}
                      </Typography>
                    </Grid>)
                    }
                    {[AuthStep.authRegister, AuthStep.authLogin].includes(
                    step,
                  ) && (
                    <>
                      

                      <Box>
                        <AuthSocialProviders />
                      </Box>
                      <Box mb={1.5} mt={1.5}>
                        <Divider
                          sx={{
                            '&::before, &::after': {
                              borderColor: 'black',
                            },
                          }}>
                          Ó
                        </Divider>
                      </Box>
                    </>
                  )}
                  <Box>{content}</Box>

                  

                  {[AuthStep.authRegister, AuthStep.authLogin].includes(
                    step,
                  ) && (
                    <Box mt={2}>
                      <AuthSwitch />
                    </Box>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </GoogleReCaptchaProvider>
  );
};