import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { SocialBar } from './SocialBar';

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    root: {
      padding: '16px 74px !important',
      paddingTop: '15px !important',
      background: '#fff',
      alignItems: 'center',
      [theme.breakpoints.down('md')]: {
        padding: '10px 10px !important',
      },
    },
    rootLogos: {
      padding: '16px 74px !important',
      paddingTop: '15px !important',
      background: '#F3F4F6',
      alignItems: 'center',
      [theme.breakpoints.down('md')]: {
        padding: '10px 10px !important',
      },
    },
    rootItems: {
      padding: '0 70px !important',
      [theme.breakpoints.down('md')]: {
        padding: '0 0px !important',
      },
    },
    item: {
      fontSize: '14px !important',
      fontWeight: 'bold !important',
      [theme.breakpoints.down('md')]: {
        fontSize: '12px !important',
        paddingBottom: '10px',
      },
    },
    boxWithBorder: {
      color: '#000',
      cursor: 'pointer',
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      textDecoration: 'none',
      textAlign: 'center',
      borderRight: '1px solid #313131',
      [theme.breakpoints.down('xl')]: {
        borderRight: 'none',
        padding: '10px 0px 0px 0px',
      },
    },
    boxWithoutBorder: {
      color: '#000',
      cursor: 'pointer',
      textDecoration: 'none',
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      textAlign: 'center',
      [theme.breakpoints.down('xl')]: {
        borderRight: 'none',
        padding: '10px 0px 0px 0px',
      },
    },
    generalBox: {
      justifyContent: 'space-between',
      alignItems: 'center',
      display: 'flex',
      width: '100%',
      background: '#fff',
    },
    flexCenter: {
      display: 'flex',
      justifyContent: 'center',
      justifyItems: 'center',
    },
    flexCenterItemsMenu: {
      display: 'flex',
      justifyContent: 'center',
      justifyItems: 'center',
    },
    socialBarClasses: {
      [theme.breakpoints.down('xl')]: {
        padding: '15px 0px 0px 0px !important',
      },
    },
  }),
);

const ItemsFooter = [
  {
    text: 'CONTACTO',
    url: 'https://vanguardia.com.mx/servicios/contacto',
  },
  {
    text: 'AVISO DE PRIVACIDAD',
    url: 'https://vanguardia.com.mx/aviso-de-privacidad',
  },
  {
    text: 'AVISO LEGAL',
    url: 'https://vanguardia.com.mx/servicios/aviso-legal',
  },
  {
    text: 'POLÍTICA EMPRESARIAL',
    url: 'https://vanguardia.com.mx/politica-empresarial',
  },
  {
    text: 'MISIÓN, VISIÓN Y VALORES',
    url: 'https://vanguardia.com.mx/mision-vision-y-valores',
  },
  {
    text: 'TÉRMINOS DE USO',
    url: 'https://vanguardia.com.mx/servicios/terminos-de-uso',
  },
];

export const FooterAuth: React.FC= () => {
  const classes = useStyles();
  return (
    <Box boxShadow= {'0 -5px 10px -5px #0000003D'}>
      
      <Grid container component={'div'} className={classes.root}>
        <Grid item xs={12} xl={10} className={classes.rootItems}>
          <Grid container xs={12}>
            {ItemsFooter.map((item, i) => {
              return (
                <Grid
                  item
                  xs={6}
                  sm={4}
                  xl={2}
                  className={classes.flexCenterItemsMenu}
                >
                  <Box
                    className={
                      i < ItemsFooter.length - 1
                        ? classes.boxWithBorder
                        : classes.boxWithoutBorder
                    }
                    target="_blank"
                    component="a"
                    href={item.url}
                    fontWeight="bold"
                    width={'100%'}
                    textAlign={'center'}
                  >
                    <Typography component={'p'} paddingX={1} className={classes.item}>
                      {item.text}
                    </Typography>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        <Grid item xs={12} xl={2} className={classes.socialBarClasses}>
          <Box className={classes.flexCenter}>
            <SocialBar />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
