import React, { ReactNode, useEffect } from 'react';
import { useAuth } from '../auth/auth-hook';
import { MainLoader } from '../../shared/components/MainLoader';
import { useNavigate } from 'react-router-dom';

type SessionProps = {
  children?: ReactNode;
};
export const Session: React.FC<SessionProps> = ({ children }) => {
  const { isAuthenticated, isLoading } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('autenticacion', {
        state: { redirectTo: window.location.href },
      });
    }
  }, [isAuthenticated, navigate]);

  if (isLoading || !isAuthenticated) return <MainLoader />;

  return <div>{children}</div>;
};
