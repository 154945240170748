import React from 'react';
import { Box, CircularProgress } from '@mui/material';

type MainLoaderProps = {
  children?: React.ReactNode;
  height?: string;
};
export const MainLoader: React.FC<MainLoaderProps> = ({ children, height }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: height || '100vh',
        width: '100%',
      }}
    >
      {children && <Box mb={1}>{children}</Box>}
      <CircularProgress color="primary" />
    </Box>
  );
};
