import React from 'react';
import { Box, Button, Hidden, IconButton, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../../modules/auth/auth-hook';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { getRedirectUrl } from '../../../../modules/auth/auth-utils';
import { APP_ENDPOINT} from '../../../constans';

type HeaderAuthProps = {
  isInvited?: boolean;
};
const useStyles = makeStyles<Theme>(() =>
  createStyles({
    logoClasses: {
      position: 'absolute',
      top: '0',
      left: '0',
      height: '100%',
      component: 'div',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      pointerEvents:'none',
    },
    button: {
      color: 'white!important',
      height: '38px !important',
      border: 'none !important',
    },
    invited: {
      height: '100%',
      component: 'div',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      position:'absolute',
      pointerEvents:'none',
    },
    
  }),
);
export const HeaderAuth: React.FC<HeaderAuthProps> = ({ isInvited }) => {
  const classes = useStyles();
  const { user } = useAuth();
  const navigate = useNavigate();
  const pathname = window.location.pathname;
  return (
    <Box
      style={{ background: '#fff' }}
      height={'75px'}
      justifyContent={isInvited ? 'space-between' : 'center'}
      alignItems={'center'}
      display={'flex'}
      position={'relative'}
      boxShadow= {'0 5px 5px -2px #0000003D'}
    >
      {
        pathname==='/planes'&&
        <Box>
          <IconButton style={{borderRadius:'5px'}} onClick={()=>window.location.href = getRedirectUrl()}>
            <ArrowBackIosIcon/>
            <Typography fontSize={{xs:12, lg:16}}>
              Atrás
            </Typography>
          </IconButton>
        </Box>
      }
      <Box
        className={isInvited && !user ? classes.invited : classes.logoClasses}
      >
        <Box position={'relative'} width={{xs:120, sm:200}} height={{xs:60}}>
          <Box component={'a'}  href={APP_ENDPOINT} style={{pointerEvents:'auto'}}>
            <img
              src="/LogoVanguardiaMX.svg"
              alt="logo"
              width={'100%'}
              height={'100%'}
              style={{ flexGrow: 1, objectFit: 'contain' , position:'absolute'}}
            />
          </Box>
        </Box>
      </Box>

      {isInvited && (
        <>
          {!user && (
            <Box display={'flex'} alignItems={'center'}>
              <Hidden mdUp={false} mdDown={true}>
                <Typography
                  style={{ color: 'white', marginRight: 15, width: '280px' }}
                >
                  ¿YA ERES UN SUSCRITOR DIGITAL?
                </Typography>
              </Hidden>
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                
                disableElevation
                style={{
                  textTransform: 'capitalize',
                  margin: 'none !important',
                  border: 'none !important',
                }}
                onClick={() => navigate('/autenticacion')}
              >
                <Typography fontSize={{xs:12, lg:16}}>Inicia Sesión</Typography>
              </Button>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};
