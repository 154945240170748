import React, { ChangeEvent, useState } from 'react';
import { Box, Grid, TextField, Typography } from '@mui/material';
import { styled } from '@mui/styles';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { ButtonDefault } from '../../../shared/components/ButtonDefault';
import { authStore } from '../auth-events';
import { useStore } from '@cobuildlab/react-simple-state';
import { getResetPasswordToken } from '../auth-utils';
import { useChangePasswordMutation } from '../../../shared/types/generated';
import { COOKIES_OPTIONS, USER_COOKIE } from '../../../shared/constans';
import { redirectToHome } from '../../../shared/utils';
import { useCookies } from 'react-cookie';
import { openDialogAlert } from '../../alert/alert-actions';
import { PRIMARY_COLOR } from '../../../shared/css/theme';
import { Key } from '@mui/icons-material';
import { SECONDARY_COLOR } from '../../../shared/css/theme';
import { useLocation } from 'react-router-dom';

const StyledVisibilityIcon = styled(VisibilityIcon)({
  cursor: 'pointer',
  color: '#999999',
  fontSize: '20px!important',
});

const StyledTypography = styled(Typography)({
  fontSize: 18,
  fontWeight: 'bold!important',
});
const StyledIconKey = styled(Key)({
  color: '#999999',
  fontSize: '20px!important',
  paddingRight: '5px',
});

const StyledButton = styled(ButtonDefault)({
  borderRadius: '4px!important',
  backgroundColor: `${PRIMARY_COLOR}!important`,
  border: 'none!important',
  height: '38px !important',
  color: 'white!important',
  '&:disabled': {
    backgroundColor: SECONDARY_COLOR + '!important',
  },
  width: 200,
});

export const AuthResetPasswordForm: React.FC = () => {
  const location = useLocation();
  const returnTo = location?.state?.returnTo as string | undefined;
  const token = getResetPasswordToken();
  const [data, setData] = useState({ password: '', repeatPassword: '' });
  const { email } = useStore(authStore);
  const [, setCookie] = useCookies([USER_COOKIE]);
  const [passwordType, setPasswordType] = useState<{
    [key: string]: string | undefined;
  }>({
    repeatPassword: undefined,
    password: undefined,
  });

  const [changeUserPassword, { loading }] = useChangePasswordMutation({
    onCompleted: ({ changePassword }) => {
      setCookie(USER_COOKIE, changePassword?.token, COOKIES_OPTIONS);
      redirectToHome(returnTo ?? '/');
    },

    onError: () =>
      openDialogAlert('Tenemos problemas para actualizar la contraseña'),
  });

  const onChange = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ): void => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };

  const onSubmit = (): void => {
    const { password, repeatPassword } = data;
    if (password !== repeatPassword) {
      return openDialogAlert('Las contraseñas deben coincidir');
    }

    changeUserPassword({
      variables: {
        email,
        password,
        token,
      },
    });
  };

  const handleVisibility = (field: string): void => {
    if (field && !passwordType[field])
      setPasswordType({ ...passwordType, [field]: 'text' });
    else setPasswordType({ ...passwordType, [field]: undefined });
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <Box mb={2}>
            <StyledTypography fontSize={'20px'}>
              Retablecer contraseña
            </StyledTypography>
          </Box>
          <Box mb={2}>
            <Typography style={{ color: 'black', textAlign: 'justify' }}>
              Para mantener la seguridad de tus datos, te solicitamos cambiar tu
              contraseña cada cierto tiempo.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <TextField
            placeholder={'Contraseña'}
            name="password"
            type={passwordType?.password ?? 'password'}
            value={data?.password}
            fullWidth
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment: (
                <StyledVisibilityIcon
                  onClick={() => handleVisibility('password')}
                />
              ),
              startAdornment: <StyledIconKey />,
              style: { fontSize: '18px', background: 'white', height: '38px' },
            }}
            onChange={(event) => onChange(event)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            placeholder={'Repetir contraseña'}
            name="repeatPassword"
            type={passwordType?.repeatPassword ?? 'password'}
            value={data?.repeatPassword}
            fullWidth
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment: (
                <StyledVisibilityIcon
                  onClick={() => handleVisibility('repeatPassword')}
                />
              ),
              startAdornment: <StyledIconKey />,
              style: { fontSize: '18px', background: 'white', height: '38px' },
            }}
            onChange={(event) => onChange(event)}
          />
        </Grid>
        <Grid item xs={12}>
          <Box textAlign="center" my={2}>
            <StyledButton
              variant="contained"
              size="large"
              fullWidth
              disabled={!(data.repeatPassword && data.password)}
              onClick={() => onSubmit()}
              isLoading={loading}>
              Continuar
            </StyledButton>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
