import React from 'react';
import {
  Divider,
  Typography,
  List,
  ListItem,
  ListItemText,
  Theme,
  Box,
  Hidden,
  Drawer,
  ListItemIcon,
} from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import { createStyles, makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import { SETTING_ROUTES } from './layout-utils';
import { PRIMARY_COLOR } from '../../../css/theme';
import { openMobileMenuEvent } from './user-events';
import { useStore } from '@cobuildlab/react-simple-state';
import { closeMobileMenu } from './user-actions';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PowerSettingsNewSharpIcon from '@mui/icons-material/PowerSettingsNewSharp';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    textRed: {
      color: PRIMARY_COLOR,
    },
    textNormal: {
      color: 'black',
      cursor: 'pointer',
    },
    listItem: {
      '& > span': {
        fontWeight: 700,
      },
    },
    helpCenter: {
      color: 'black',
      '&:hover': {
        color: '#0080ff',
      },
    },
  }),
);

export const Menu: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { open } = useStore(openMobileMenuEvent);

  return (
    <>
      <Hidden mdDown={false} mdUp={true}>
        <Drawer anchor="left" open={open} onClose={() => closeMobileMenu()}>
          <Box sx={{ width: 250 }} onClick={() => closeMobileMenu()}>
            <ListItem divider>
              <Typography color="primary" fontWeight="bold">
                TU CONTENIDO
              </Typography>
            </ListItem>
            {SETTING_ROUTES.map((route) => {
              return (
                <ListItem
                  divider
                  className={
                    route.path === location.pathname
                      ? classes.textRed
                      : classes.textNormal
                  }
                  onClick={() => {
                    navigate(route.path);
                  }}
                >
                  <ListItemText className={route.path === location.pathname
                      ? classes.listItem:undefined} primary={route.name} />
                  <ArrowForwardIosIcon fontSize="small" />
                </ListItem>
              );
            })}
            
              <ListItem
                  divider
                  className={
                    classes.textNormal
                  }
                  component="a"
                  href="https://vanguardia.com.mx/newsletters"
                  target="_blank"
                >
                <ListItemText primary={'Newsletters'} />
                <ArrowForwardIosIcon fontSize="small" />
              </ListItem>
            

            <List component="nav" aria-label="mailbox folders">
              <ListItem divider>
                <Typography fontWeight="bold">AYUDA</Typography>
              </ListItem>
            </List>

            <List component="nav" aria-label="mailbox folders">
              <ListItem
                divider
                component="a"
                href="mailto:suscripciones@vanguardia.com.mx"
                target="_blank"
                style={{ color: 'black' }}
              >
                <ListItemText primary="Escríbenos" />
                <MailOutlineIcon fontSize="small" />
              </ListItem>
              <Divider />
              <a
                style={{ textDecoration: 'none', color: 'black' }}
                target="_blank"
                href="https://api.whatsapp.com/send/?phone=528443808728&text=Hola.+Me+estoy+contactando+desde+el+portal+de+vanguardia.com.mx&type=phone_number&app_absent=0"
              >
                <ListItem divider>
                  <ListItemText
                    style={{ fontSize: '16px' }}
                    primary="Mensaje a Whatsapp"
                  />
                  <WhatsAppIcon fontSize="small" />
                </ListItem>
              </a>
              <Divider />
              <a
                className={classes.textRed}
                style={{ textDecoration: 'none', fontWeight: 'bold' }}
                href="https://vanguardia.com.mx"
              >
                <ListItem divider>
                  <ListItemText
                    className={classes.listItem}
                    primary="IR A VANGUARDIA"
                  />
                  <ArrowOutwardIcon fontSize="small" />
                </ListItem>
              </a>
              <div style={{ marginTop: '100px' }}>
                <Divider />
                <ListItem
                  className={classes.textNormal}
                  onClick={() => navigate('/logout')}
                >
                  <ListItemIcon>
                    <PowerSettingsNewSharpIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Cerrar sesión" />
                </ListItem>
              </div>
            </List>
          </Box>
        </Drawer>
      </Hidden>
      <Hidden mdDown={true} mdUp={false}>
        <Box>
          <List component="nav" aria-label="mailbox folders">
            <ListItem divider>
              <Typography color="primary" fontWeight="bold">
                TU CONTENIDO
              </Typography>
            </ListItem>
            {SETTING_ROUTES.map((route) => {
              return (
                <ListItem
                  divider
                  className={
                    route.path === location.pathname
                      ? classes.textRed
                      : classes.textNormal
                  }
                  onClick={() => {
                    navigate(route.path);
                  }}
                >
                  <ListItemText className={route.path === location.pathname
                      ? classes.listItem:undefined} primary={route.name} />
                  <ArrowForwardIosIcon fontSize="small" />
                </ListItem>
              );
            })}
            <ListItem
                divider
                className={
                  classes.textNormal
                }
                component="a"
                href="https://vanguardia.com.mx/newsletters"
                target="_blank"
              >
              <ListItemText primary={'Newsletters'} />
              <ArrowForwardIosIcon fontSize="small" />
            </ListItem>
          </List>
          <List component="nav" aria-label="mailbox folders">
            <ListItem divider>
              <Typography fontWeight="bold">AYUDA</Typography>
            </ListItem>
          </List>

          <List component="nav" aria-label="mailbox folders">
            <ListItem
              divider
              component="a"
              href="mailto:suscripciones@vanguardia.com.mx"
              target="_blank"
              style={{ color: 'black' }}
            >
              <ListItemText primary="Escríbenos" />
              <MailOutlineIcon fontSize="small" />
            </ListItem>
            <Divider />
            <a
              style={{ textDecoration: 'none', color: 'black' }}
              target="_blank"
              href="https://api.whatsapp.com/send/?phone=528443808728&text=Hola.+Me+estoy+contactando+desde+el+portal+de+vanguardia.com.mx&type=phone_number&app_absent=0"
            >
              <ListItem divider>
                <ListItemText
                  style={{ fontSize: '16px' }}
                  primary="Mensaje a Whatsapp"
                />
                <WhatsAppIcon fontSize="small" />
              </ListItem>
            </a>
            <Divider />
            <a
              className={classes.textRed}
              style={{ textDecoration: 'none', fontWeight: 'bold' }}
              href="https://vanguardia.com.mx"
            >
              <ListItem divider>
                <ListItemText
                  className={classes.listItem}
                  primary="IR A VANGUARDIA"
                />
                <ArrowOutwardIcon fontSize="small" />
              </ListItem>
            </a>
            <div style={{ marginTop: '100px' }}>
              <Divider />
              <ListItem
                className={classes.textNormal}
                onClick={() => navigate('/logout')}
              >
                <ListItemIcon>
                  <PowerSettingsNewSharpIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Cerrar sesión" />
              </ListItem>
            </div>
          </List>
        </Box>
      </Hidden>
    </>
  );
};
