import { createStoreAction } from '@cobuildlab/react-simple-state';
import {
  changeAvatarStore,
  providerConnectStore,
  updateProfileStore,
} from './setting-events';
import { SocialProviders } from '../../shared/types/generated';
import { changeEmailDialogStore } from './setting-stores';

export const changeAvatarAction = createStoreAction(
  changeAvatarStore,
  (prev, file: File | null) => ({
    ...prev,
    file,
  }),
);

export const providerConnectStoreAction = createStoreAction(
  providerConnectStore,
  (prev, isOpen: boolean, provider: SocialProviders | undefined) => ({
    ...prev,
    isOpen,
    provider,
  }),
);

export const updateProfileStoreAction = createStoreAction(
  updateProfileStore,
  (prev, isChanged: boolean) => ({
    ...prev,
    isChanged,
  }),
);

export const changeEmailDialogStoreAction = createStoreAction(
  changeEmailDialogStore,
  (prev, open: boolean) => ({
    ...prev,
    open,
  }),
);
