import React from 'react';
import { Box, Theme } from '@mui/material';

import XIcon from '@mui/icons-material/X';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import { createStyles, makeStyles } from '@mui/styles';
const useStyles = makeStyles<Theme>(() =>
  createStyles({
    itemSocialBar: {
      color: '#FFFFFF',
      cursor: 'pointer',
      textDecoration: 'none',
      height: '28px',
      width: '28px',
      borderRadius: '100%',
      background: 'white',
      fontWeight: 'bold',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: 10,
    },
    logosColor: {
      color: 'black',
    },
  }),
);
export const SocialBar: React.FC = () => {
  const classes = useStyles();
  return (
    <Box
      justifyContent={'center'}
      alignItems={'center'}
      display={'flex'}
      component={'div'}
      style={{ background: '#fff' }}
      flexWrap={'wrap'}
    >
      <Box
        className={classes.itemSocialBar}
        target="_blank"
        component="a"
        href="https://www.twitter.com/vanguardiamx"
      >
        <XIcon className={classes.logosColor} fontSize="medium" />
      </Box>
      <Box
        className={classes.itemSocialBar}
        target="_blank"
        component="a"
        href="https://www.facebook.com/vanguardiamx"
        fontWeight="bold"
      >
        <FacebookIcon className={classes.logosColor} fontSize="medium" />
      </Box>
      <Box
        className={classes.itemSocialBar}
        target="_blank"
        component="a"
        href="https://www.youtube.com/user/vanguardiamedia"
        fontWeight="bold"
      >
        <YouTubeIcon className={classes.logosColor} fontSize="medium" />
      </Box>
      <Box
        className={classes.itemSocialBar}
        target="_blank"
        component="a"
        href="https://instagram.com/vanguardiamx"
        fontWeight="bold"
      >
        <InstagramIcon className={classes.logosColor} fontSize="medium" />
      </Box>
     
    </Box>
  );
};
