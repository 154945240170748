import { createContext } from 'react';
import { User } from '../../shared/types/generated';

export type DefaultValuesContextType = {
  user?: User | null;
  isAuthenticated: boolean;
  refetch: () => void;
  isLoading?: boolean;
  provider?: string;
};

const defaultValues = {
  isAuthenticated: false,
  isLoading: true,
  user: undefined,
  provider: undefined,
  refetch: () => {},
};

export const Context = createContext<DefaultValuesContextType>(defaultValues);

export const { Consumer } = Context;
export const { Provider } = Context;
