import moment from 'moment/moment';

export const FACEBOOK_CALLBACK = process.env.REACT_APP_FACEBOOK_CALLBACK;
export const FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID;
export const GOOGLE_CAPTCHA = process.env.REACT_APP_GOOGLE_CAPTCHA;
export const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
export const APP_ENV = process.env.REACT_APP_ENV || 'local';

export const ANALYTICS_ENDPOINT =
  process.env.REACT_APP_ANALYTICS_ENDPOINT || '';
export const DOMAIN_SITE = `.${process.env.REACT_APP_DOMAIN || ''}`;
export const APP_ENDPOINT = `${process.env.REACT_APP_ENDPOINT || ''}`;

export const COOKIES_OPTIONS = {
  expires: moment().add(1, 'year').toDate(),
  path: '/',
  domain: DOMAIN_SITE,
};

export const ANALYTICS_COOKIES_OPTIONS = {
  expires: moment().add(1, 'day').toDate(),
  path: '/',
  domain: DOMAIN_SITE,
};

export const USER_COOKIE = '_uew';
export enum PaymentSelection {
  OpenPay = 'OPEN_PAY',
  PayPal = 'PAYPAL',
  stripe= 'STRIPE'
}

export const GUEST_ROLE = 'GUEST';

export const MAILCHIMP_API_KEY = process.env.REACT_APP_MAILCHIMP_API_KEY || '';
export const MAILCHIMP_SERVER_PREFIX =
  process.env.REACT_APP_MAILCHIMP_SERVER_PREFIX || '';
export const MAILCHIMP_AUDIENCE =
  process.env.REACT_APP_MAILCHIMP_AUDIENCE || '';

export const STRIPE_KEY= process.env.REACT_APP_STRIPE_KEY || '';
export const STRIPE_SECRET_KEY= process.env.REACT_APP_STRIPE_SECRET_KEY || '';
export   const fondosLayout=[
  {
    image:'/Img-ejemplo-transito.png',
    author:'Omar Saucedo'
  }
];
export type BenefitSubscription = {
  label: string;
  image: string;
  description: string;
};
export const BENEFIT_SUBSCRIPTIONS: BenefitSubscription[] = [
  {
    label: 'Lectura ilimitada',
    image:
      'https://digitalroom.nyc3.cdn.digitaloceanspaces.com/vanguardia-mobile/benefit-subscriptions/lectura-ilimitada.png',
    description:
      'Accede a nuestros articulos y autores excusivos sin anuncios ni interrupciones.',
  },
  {
    label: 'Escucha artículos',
    description:
      'Escucha nuestros artículos seleccionados y añádelos a tu lista de reproducción.',
    image:
      'https://digitalroom.nyc3.cdn.digitaloceanspaces.com/vanguardia-mobile/benefit-subscriptions/escucha-articulos.png',
  },
  {
    label: 'Ofertas únicas',
    description:
      'Tu membresia, ¡se paga sola!',
    image:
      'https://digitalroom.nyc3.cdn.digitaloceanspaces.com/vanguardia-mobile/benefit-subscriptions/ofertas-unicas.png',
  },
  {
    label: 'Periodismo de investigación',
    description:
      'No te pierdas las historias mas increibles de Coahuila y la region Noreste del pais.',
    image:
      'https://digitalroom.nyc3.cdn.digitaloceanspaces.com/vanguardia-mobile/benefit-subscriptions/periodismo-de-investigacion.png',
  },
  {
    label: 'Guarda tus artículos',
    description:
      '¿No tienes tiempo para leer una nota en este momento? iGuardala para después!',
    image:
      'https://digitalroom.nyc3.cdn.digitaloceanspaces.com/vanguardia-mobile/benefit-subscriptions/guarda-tus-articulos-favoritos.png',
  },
  {
    label: 'ePaper',
    description:
      'Lee las noticias en un formato tradicional y amigable con el medio ambiente.',
    image:
      'https://digitalroom.nyc3.cdn.digitaloceanspaces.com/vanguardia-mobile/benefit-subscriptions/epaper.png',
  },
  {
    label: 'Boletines exclusivos',
    description:
      'Accede a boletines e informacion exclusiva, curada para formato email',
    image:
      'https://digitalroom.nyc3.cdn.digitaloceanspaces.com/vanguardia-mobile/benefit-subscriptions/boletines-exclusivos.png',
  },
  {
    label: 'Comparte tu opinión',
    description:
      'No te quedes con ganas de comentar los articulos, iparticipa en el debate!',
    image:
      'https://digitalroom.nyc3.cdn.digitaloceanspaces.com/vanguardia-mobile/benefit-subscriptions/comparte-tu-opinion.png',
  },
  {
    label: 'Configura tu experiencia',
    description:
      'iLee en modo oscuro, cambia el tamafio de letra, filtra tus busquedas y més!',
    image:
      'https://digitalroom.nyc3.cdn.digitaloceanspaces.com/vanguardia-mobile/benefit-subscriptions/configura-tu-experiencia.png',
  },
  {
    label: 'Personaliza tu lectura',
    description:
      'Desbloquea la personalizacién y elige qué contenido quieres recibir todos los dias.',
    image:
      'https://digitalroom.nyc3.cdn.digitaloceanspaces.com/vanguardia-mobile/benefit-subscriptions/persolaniza-tu-lectura.png',
  },
];