import { Box, Theme, Typography } from '@mui/material';
import { authStepAction } from '../auth-actions';
import React from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { useStore } from '@cobuildlab/react-simple-state';
import { authStepStore } from '../auth-events';
import { AuthStep } from '../auth-types';


const useStyles = makeStyles<Theme>(() =>
  createStyles({
    selected: {
      color: '#000!important',
      background: '#FFFFFF!important',
    },
    unselected: {
      color: '#8A8A8A!important',
      background: '#EBEBEB!important',
    },
    container: {
      borderRadius: '5px',
      display: 'flex',
      justifyContent: 'center',
    },
  }),
);

export const AuthSwitch: React.FC = () => {
  const { step } = useStore(authStepStore);
  const classes = useStyles();


  return (
    <Box width="100%" display="flex" justifyContent="center">
      <Box className={classes.container}>
        {
          step === AuthStep.authLogin ?
            <Typography fontSize={'14px'}>
              No tengo cuenta <span role="button" tabIndex={0} onClick={() => {
              authStepAction(AuthStep.authRegister);
            }}
                                    style={{ textDecoration: 'underline', cursor: 'pointer' }}>Regístrate</span>
            </Typography> : null
        }
        {
          step === AuthStep.authRegister ?
            <Typography fontSize={'14px'}>
              Ya tengo cuenta <span role="button" tabIndex={0} onClick={() => {
              authStepAction(AuthStep.authLogin);
            }}
                                    style={{ textDecoration: 'underline', cursor: 'pointer' }}>Iniciar sesión</span>
            </Typography> : null
        }

      </Box>
    </Box>
  );
};