import React, { ReactNode } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { ButtonDefault } from '../ButtonDefault';

type DialogDefaultProps = {
  title: string;
  children: ReactNode;
  handleSuccess?: () => void;
  isOpen: boolean;
  handleClose?: () => void;
  isLoading?: boolean;
  showActions?: boolean;
};

export const DialogDefault: React.FC<DialogDefaultProps> = ({
  title,
  children,
  handleSuccess,
  handleClose,
  isOpen,
  isLoading = false,
  showActions = false,
}) => {
  return (
    <Dialog
      open={isOpen}
      onClose={() => handleClose && handleClose()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      {showActions && (
        <DialogActions>
          <Button
            color="primary"
            variant="outlined"
            onClick={() => handleClose && handleClose()}
            style={{ height: 42, width: 150 }}
          >
            Cancelar
          </Button>
          <ButtonDefault
            onClick={() => handleSuccess && handleSuccess()}
            isLoading={isLoading}
            
            style={{ height: 42, width: '150px' }}
          >
            Confirmar
          </ButtonDefault>
        </DialogActions>
      )}
    </Dialog>
  );
};
