import { createTheme } from '@mui/material/styles';
export const PRIMARY_COLOR_MINIM= '#000000';
export const PRIMARY_COLOR = '#E8600B';
export const SECONDARY_COLOR = '#c7500a70';

export const theme = createTheme({
  palette: {
    primary: {
      main: PRIMARY_COLOR,
    },
    secondary: {
      main: SECONDARY_COLOR,
    },
  },
  //"PT Sans",helvetica,arial,sans-serif
  typography: {
    fontFamily: [
      '"PT Serif"',
      '"PT Sans"',
      'helvetica',
      'arial',
      'sans-serif',
    ].join(','),
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            background: '#D5DCE6',
            color: '#fff',
          },
          '&': {
            border: '1px solid #CCCCCC',
            color: '#999999',
          },
        },
      },
    },
  },
});
