import { createStore } from '@cobuildlab/react-simple-state';
import { DialogAlertEnum } from './alert-types';

export const dialogAlertStore = createStore<{
  isOpen: boolean;
  message: string | undefined;
  type: DialogAlertEnum | undefined;
  extraAction?: () => void;
}>({
  initialValue: {
    isOpen: false,
    message: undefined,
    type: undefined,
    extraAction: undefined,
  },
});
