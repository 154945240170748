import {
  createAction,
  createStoreAction,
} from '@cobuildlab/react-simple-state';
import {
  authStepStore,
  authStore,
  fetchGoogleUserErrorEvent,
  fetchGoogleUserEvent,
  OnTokenErrorEvent,
  OnTokenEvent,
} from './auth-events';
import axios from 'axios';
import { AuthStep } from './auth-types';

export const fetchUserGoogle = createAction(
  fetchGoogleUserEvent,
  fetchGoogleUserErrorEvent,
  async (token) => {
    const response = await axios.get(
      `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${token.access_token}`,
      {
        headers: {
          Authorization: `Bearer ${token.access_token.access_token}`,
          Accept: 'application/json',
        },
      },
    );

    return response.data;
  },
);

export const fetchToken = createAction(
  OnTokenEvent,
  OnTokenErrorEvent,
  async (token) => {
    if (token)
      return {
        token,
      };

    throw Error('The token is required');
  },
);

export const authStoreAction = createStoreAction(
  authStore,
  (prev, email: string, isLogin: boolean) => ({
    ...prev,
    email,
    isLogin,
  }),
);

export const authStepAction = createStoreAction(
  authStepStore,
  (prev, step: AuthStep) => ({ ...prev, step }),
);
