import { createStoreAction } from '@cobuildlab/react-simple-state';
import { openMobileMenuEvent } from './user-events';

export const openMobileMenu = createStoreAction(
  openMobileMenuEvent,
  (prev) => ({
    ...prev,
    open: true,
  }),
);

export const closeMobileMenu = createStoreAction(
  openMobileMenuEvent,
  (prev) => ({
    ...prev,
    open: false,
  }),
);
