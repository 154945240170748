import React from 'react';
import { Box, Grid } from '@mui/material';
import { getIsInStandaloneMode } from '../auth-utils';
import { GoogleButton } from './GoogleButton';
import { isAndroid } from 'react-device-detect';
import { FacebookButton } from './FacebookButton';
import { APP_ENV } from '../../../shared/constans';
import { AppleButton } from './AppleButton';

export const AuthSocialProviders: React.FC = () => {
  const isWebView =
    navigator.userAgent.toLowerCase().includes('wv') ||
    navigator.userAgent.toLowerCase().includes('magpoc');

  return (
    <Grid item xs={12}>
      <Grid
        container
        spacing={
          !navigator.userAgent.match(/FBAN|FBAV/i) &&
          !getIsInStandaloneMode() &&
          !isWebView
            ? 2
            : 1
        }
      >
        {!navigator.userAgent.match(/FBAN|FBAV/i) &&
        !getIsInStandaloneMode() &&
        !isWebView ? (
          <Grid item xs={12}>
            <Box textAlign="center">
              <GoogleButton />
            </Box>
          </Grid>
        ) : null}
        {!(isWebView && isAndroid) && (
          <Grid item xs={12}>
            <Box textAlign="center">
              <FacebookButton />
            </Box>
          </Grid>
        )}

        {!navigator.userAgent.match(/FBAN|FBAV/i) &&
        APP_ENV !== 'local' &&
        !getIsInStandaloneMode() &&
        !isWebView ? (
          <Grid item xs={12}>
            <Box textAlign="center">{<AppleButton />}</Box>
          </Grid>
        ) : null}
      </Grid>
    </Grid>
  );
};
